import { Component } from "react";

class Footer extends Component {
	render() {
		return (
			<div className="footer">
				<p>
				Per <a href="https://twitter.com/HuguetAndreu" rel="noreferrer" target="_blank">Andreu Huguet</a> i <a href="https://twitter.com/uriiisegura" rel="noreferrer" target="_blank">Oriol Segura</a>.
				</p>
				<p>
				Especial agraïment a <a href="https://twitter.com/ndreu99" rel="noreferrer" target="_blank">Andreu Roig</a> i a tothom involucrat per la seva col·laboració i professionalitat.
				</p>
				<p>
				Codi obert al <a href="https://github.com/Huguet57/barometre" rel="noreferrer" target="_blank">GitHub</a>, col·labora-hi tu també!
				</p>
			</div>
		);
	}
}

export default Footer;
